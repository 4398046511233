<template>
  <button class="form-button">
    <slot />
  </button>
</template>

<style lang="postcss" scoped>
@layer BaseComponents {
  .form-button {
    @apply cursor-pointer font-semibold inline-block text-center rounded-full text-white w-full py-3 px-6 body-1 disabled:bg-primary-100 disabled:text-primary-25 disabled:cursor-not-allowed;
  }
}
</style>
